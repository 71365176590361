import React from "react";
import "./SoftwareSkill.css";
import { skillsSection } from "../../portfolio";

export default function SoftwareSkill() {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((skills) => {
            return (
              <li className="software-skill-inline" name={skills.skillName}>
                <i
                  className={skills.fontAwesomeClassname}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={skills.skillName}
                ></i>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
