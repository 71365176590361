import React from "react";
import "./StartupProjects.css";
import { Fade } from "react-reveal";

export default function StartupProject() {
  function openProjectInNewWindow(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <div className="main" id="projects">
      <div>
        <h1 className="skills-heading">Experience 🏅</h1>
        <p className="subTitle project-subtitle">
          Startups, companies and projects that i have been involved with
        </p>
        <div id="skills">
          <div className="skills-main-div">
            <div className="skills-image-div">
              <img
                alt="leap.club"
                onClick={() => openProjectInNewWindow("https://leap.club")}
                src={require("../../assets/images/leapclub.jpg")}
              ></img>
            </div>
            <div className="skills-text-div">
              <h2
                onClick={() => openProjectInNewWindow("https://leap.club")}
                className="company-heading"
              >
                <a>leap.club</a>
              </h2>
              <p className="subTitle skills-text-subtitle">
                AVP, Engineering
                <br />
              </p>
              <div>
                <p className="subTitle skills-text">
                  Building a powerful private network of rising women leaders.
                  Mission - more women in leadership positions.
                  <br /> <br />
                  Responsible for designing, building & maintaining highly
                  scalable, micro services based backend. Handling dev-ops,
                  database, etc
                </p>
                <p className="subTitle skills-text">
                  <a target="_blank" href="https://leap.club/">
                    learn more
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="skills">
          <div className="skills-main-div">
            <div className="skills-image-div">
              <img
                alt="Boltpics"
                onClick={() => openProjectInNewWindow("https://boltpics.com")}
                src={require("../../assets/images/boltpics.jpg")}
              ></img>
            </div>
            <div className="skills-text-div">
              <h2
                onClick={() => openProjectInNewWindow("https://boltpics.com")}
                className="company-heading"
              >
                Boltpics
              </h2>
              <p className="subTitle skills-text-subtitle">
                Co-Founder, Technology
              </p>
              <div>
                <p className="subTitle skills-text">
                  Boltpics is a Smart Photo Distribution platform, based on
                  proprietary facial recognition algorithms, to deliver you your
                  event photos in near realtime. Currently runs as a white label
                  solutions for multiple event companies.
                </p>
                <p className="subTitle skills-text">
                  <a
                    target="_blank"
                    href="https://www.google.com/search?q=boltpics&sxsrf=APwXEddyQG8ZjfZg5WE6UWsbib_mc4t9TQ%3A1683120244502&ei=dGBSZPqGHrSTxc8PqeyL-AE&ved=0ahUKEwj6kKbCn9n-AhW0SfEDHSn2Ah8Q4dUDCA8&uact=5&oq=boltpics&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCAAQgAQ6BwgjELADECc6CggAEEcQ1gQQsAM6DwguEIoFEMgDELADEEMYAToSCC4QigUQ1AIQyAMQsAMQQxgBOg0ILhCKBRDHARDRAxBDOgcIABCKBRBDOgcILhCKBRBDOgsILhCABBDHARCvAToHCAAQgAQQCjoKCC4QgAQQ1AIQCjoECAAQHjoJCAAQHhDxBBAKSgQIQRgAUP8DWPkLYJENaAFwAXgAgAF9iAHIA5IBAzQuMZgBAKABAcgBDcABAdoBBAgBGAg&sclient=gws-wiz-serp#lrd=0x3bc2c1dc4ef10c89:0x7291b3007cbe5f39,1,,,,"
                  >
                    testimonials
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="skills">
          <div className="skills-main-div">
            <div className="skills-image-div">
              <img
                alt="Busflix"
                onClick={() => openProjectInNewWindow("http://busflix.in")}
                src={require("../../assets/images/busflix2.jpg")}
              ></img>
            </div>
            <div className="skills-text-div">
              <h2
                onClick={() => openProjectInNewWindow("http://busflix.in")}
                className="company-heading"
              >
                <a>Busflix</a>
              </h2>
              <p className="subTitle skills-text-subtitle">
                Co-Founder, CEO
                <br />
                Responsible for tech, hardware design, sales, business
                development, team building, etc
              </p>
              <div>
                <p className="subTitle skills-text">
                  Busflix is a hardware-software infotainment system for
                  intercity buses, with features like Wifi entertaiment , GPS
                  based automated announcement system, Smart LCD screens etc.
                  Running across 700+ buses across India.
                </p>
                <p className="subTitle skills-text">
                  <a
                    target="_blank"
                    href={require("../../assets/pdf/Busflix.e0bd96dc.pdf")}
                  >
                    learn more
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="skills">
          <div className="skills-main-div">
            <div className="skills-image-div">
              <img
                alt="Simple Solutions"
                onClick={() =>
                  openProjectInNewWindow("https://www.canstores.com/")
                }
                src={require("../../assets/images/canstores.jpg")}
              ></img>
            </div>
            <div className="skills-text-div">
              <h2
                onClick={() =>
                  openProjectInNewWindow("https://www.canstores.com/")
                }
                className="company-heading"
              >
                Canstores
              </h2>
              <p className="subTitle skills-text-subtitle">
                Technology Partner
              </p>
              <div>
                <p className="subTitle skills-text">
                  (now defunct)Modern day hyperlocal retail e-commerce listing
                  portal. Multiple stake-holders like merchants, retailers,
                  buyers, each having its own dashboard and controls.
                </p>
                <p className="subTitle skills-text">
                  <a target="_blank" href="https://youtu.be/UryQ0QD-VqE">
                    learn more
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="skills">
          <div className="skills-main-div">
            <div className="skills-image-div">
              <img
                alt="Simple Solutions"
                onClick={() => openProjectInNewWindow("http://qssoindia.in/")}
                src={require("../../assets/images/qsso.png")}
              ></img>
            </div>
            <div className="skills-text-div">
              <h2
                onClick={() => openProjectInNewWindow("http://qssoindia.in/")}
                className="company-heading"
              >
                Simple Solutions
              </h2>
              <p className="subTitle skills-text-subtitle">
                Technology Consultant
              </p>
              <div>
                <p className="subTitle skills-text">
                  Simple Solutions is a software services company which caters
                  to Web development, Android App development, Cloud
                  infrastructure management, Video production, photography.{" "}
                </p>
                <p className="subTitle skills-text">
                  <a target="_blank" href="http://qssoindia.in/">
                    learn more
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="skills">
          <div className="skills-main-div">
            <div className="skills-image-div">
              <img
                alt="Simple Solutions"
                onClick={() =>
                  openProjectInNewWindow(
                    "https://en.wikipedia.org/wiki/ABU_Robocon"
                  )
                }
                src={require("../../assets/images/robocon_2013.jpg")}
              ></img>
            </div>
            <div className="skills-text-div">
              <h2
                onClick={() =>
                  openProjectInNewWindow(
                    "https://en.wikipedia.org/wiki/ABU_Robocon"
                  )
                }
                className="company-heading"
              >
                ABU Robocon 2013
              </h2>
              <p className="subTitle skills-text-subtitle">Team member</p>
              <div>
                <p className="subTitle skills-text">
                  Member of MIT(PUNE) Tech team which won the Robocon 2013 India
                  nationals. The theme for Robocon 2013 declared by VTV was "The
                  Green Planet". Team went on to represent India in Vietnam for
                  the internationals.
                  <br />
                  Worked on modern-age embedded technologies.
                </p>
                <p className="subTitle skills-text">
                  <a
                    target="_blank"
                    href="https://en.wikipedia.org/wiki/ABU_Robocon"
                  >
                    learn more
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="skills">
          <div className="skills-main-div">
            <div className="skills-image-div">
              <img
                alt="Digital electronics book"
                src={require("../../assets/images/book.jpg")}
              ></img>
            </div>
            <div className="skills-text-div">
              <h2 className="company-heading">
                Digital Electronics Practical Lab Manual
              </h2>
              <p className="subTitle skills-text-subtitle">Writer, Publisher</p>
              <div>
                <p className="subTitle skills-text">
                  Realised the need for a standardized compilation of study
                  material for Pune University Practical Lab examinations.
                  <br />
                  Managed the complete content writing, publishing and sales
                  across multiple colleges that adhere to Pune University
                  curricular. Sold 500+ copies and ran the venture profitably,
                  after investing in personal capacity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
