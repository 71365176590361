import React from "react";
import "./Education.css";
import { Fade } from "react-reveal";

const Education = () => (
  <Fade left duration={1000} distance="20px">
    <div className="main edu-margin-top" id="education">
      <div className="edu-div-main">
        <div className="edu-header">
          <h1 className="heading edu-title">Education 🎓</h1>
          <p className="edu-text-div">
            Graduated with a Bachelor's Degree (B.E.) in Electronics &
            Telecommunication Engineering from MIT , Pune.
          </p>
        </div>
      </div>
    </div>
  </Fade>
);

export default Education;
