import React, { Component } from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import StartupProject from "./StartupProjects/StartupProject";
import Contact from "./contact/Contact";
import Footer from "../components/footer/Footer";
import Top from "./topbutton/Top";
import Education from "./Education/Education";
import { Scrollbars } from "react-custom-scrollbars";

export default class Main extends Component {
  render() {
    return (
      <Scrollbars style={{ width: "100%", height: "100vh" }} autoHide>
        <div>
          <Header />
          <Greeting />
          <Skills />
          <StartupProject />
          <Education />
          <Contact />
          <Footer />
          <Top />
        </div>
      </Scrollbars>
    );
  }
}
